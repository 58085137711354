// TODO extract links to consts
export const cardsData = [
  {
    headerText: 'Mavryk Network',
    iconId: 'mav-logo-small',
    link: 'https://mavryk.org/',
    description: (
      <>
        Mavryk is a bespoke Layer 1 blockchain, with enshrined rollups to
        empower traditional assets as tokenized RWAs, seamlessly integrating
        with DeFi.
      </>
    ),
  },
  {
    headerText: 'Maven Finance',
    iconId: 'rocket-small',
    link: 'https://atlasnet.mavenfinance.io/',
    description: (
      <>
        Mavryk Finance is a decentralized, non-custodial, community owned
        financial platform, that allows users to lend, borrow, and earn on their
        own terms.
      </>
    ),
  },
  {
    headerText: 'Equiteez',
    iconId: 'eq',
    link: 'https://equiteez.com/',
    description: (
      <>
          The future of real estate ownership is digital. Start with just $50 to
          invest in fractionalized, fully compliant, income producing assets
          worldwide.
      </>
    ),
  },
  {
    headerText: 'Mavryk Wallet',
    iconId: 'mw',
    link: 'https://chromewebstore.google.com/detail/mavryk-wallet/cgddkajmbckbjbnondgfcbcojjjdnmji',
    description: (
      <>
          Unlock a realm of maverick opportunities with the Mavryk Wallet — a
          user-centric solution empowering mainstream users to effortlessly
          navigate crypto, real-world assets, DeFi, and collectibles
      </>
    ),
  },
];
