import React from 'react';

// pages
import { LandingPage } from 'pages/Landing';

// global styles setup
import 'styles/grid.module.css';

// TODO add routes
function App() {
  return <LandingPage />;
}

export default App;
